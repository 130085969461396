var gapi = (window.gapi = window.gapi || {});
gapi._bs = new Date().getTime();
(function () {
  /*

  Copyright The Closure Library Authors.
  SPDX-License-Identifier: Apache-2.0
 */
  var n,
    aa = function (a) {
      var b = 0;
      return function () {
        return b < a.length ? { done: !1, value: a[b++] } : { done: !0 };
      };
    },
    ba =
      "function" == typeof Object.defineProperties
        ? Object.defineProperty
        : function (a, b, c) {
            if (a == Array.prototype || a == Object.prototype) return a;
            a[b] = c.value;
            return a;
          },
    ca = function (a) {
      a = [
        "object" == typeof globalThis && globalThis,
        a,
        "object" == typeof window && window,
        "object" == typeof self && self,
        "object" == typeof global && global,
      ];
      for (var b = 0; b < a.length; ++b) {
        var c = a[b];
        if (c && c.Math == Math) return c;
      }
      throw Error("Cannot find global object");
    },
    da = ca(this),
    ea = function (a, b) {
      if (b)
        a: {
          var c = da;
          a = a.split(".");
          for (var d = 0; d < a.length - 1; d++) {
            var e = a[d];
            if (!(e in c)) break a;
            c = c[e];
          }
          a = a[a.length - 1];
          d = c[a];
          b = b(d);
          b != d &&
            null != b &&
            ba(c, a, { configurable: !0, writable: !0, value: b });
        }
    };
  ea("Symbol", function (a) {
    if (a) return a;
    var b = function (f, g) {
      this.ba = f;
      ba(this, "description", { configurable: !0, writable: !0, value: g });
    };
    b.prototype.toString = function () {
      return this.ba;
    };
    var c = "jscomp_symbol_" + ((1e9 * Math.random()) >>> 0) + "_",
      d = 0,
      e = function (f) {
        if (this instanceof e)
          throw new TypeError("Symbol is not a constructor");
        return new b(c + (f || "") + "_" + d++, f);
      };
    return e;
  });
  ea("Symbol.iterator", function (a) {
    if (a) return a;
    a = Symbol("Symbol.iterator");
    for (
      var b =
          "Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(
            " "
          ),
        c = 0;
      c < b.length;
      c++
    ) {
      var d = da[b[c]];
      "function" === typeof d &&
        "function" != typeof d.prototype[a] &&
        ba(d.prototype, a, {
          configurable: !0,
          writable: !0,
          value: function () {
            return fa(aa(this));
          },
        });
    }
    return a;
  });
  var fa = function (a) {
      a = { next: a };
      a[Symbol.iterator] = function () {
        return this;
      };
      return a;
    },
    ha = function (a, b) {
      a instanceof String && (a += "");
      var c = 0,
        d = !1,
        e = {
          next: function () {
            if (!d && c < a.length) {
              var f = c++;
              return { value: b(f, a[f]), done: !1 };
            }
            d = !0;
            return { done: !0, value: void 0 };
          },
        };
      e[Symbol.iterator] = function () {
        return e;
      };
      return e;
    };
  ea("Array.prototype.keys", function (a) {
    return a
      ? a
      : function () {
          return ha(this, function (b) {
            return b;
          });
        };
  });
  var q = this || self,
    ia = function (a) {
      var b = typeof a;
      return "object" != b ? b : a ? (Array.isArray(a) ? "array" : b) : "null";
    },
    la = function (a) {
      var b = ia(a);
      return "array" == b || ("object" == b && "number" == typeof a.length);
    },
    ma = function (a) {
      var b = typeof a;
      return ("object" == b && null != a) || "function" == b;
    },
    na = "closure_uid_" + ((1e9 * Math.random()) >>> 0),
    oa = 0,
    pa = function (a, b, c) {
      return a.call.apply(a.bind, arguments);
    },
    qa = function (a, b, c) {
      if (!a) throw Error();
      if (2 < arguments.length) {
        var d = Array.prototype.slice.call(arguments, 2);
        return function () {
          var e = Array.prototype.slice.call(arguments);
          Array.prototype.unshift.apply(e, d);
          return a.apply(b, e);
        };
      }
      return function () {
        return a.apply(b, arguments);
      };
    },
    ra = function (a, b, c) {
      ra =
        Function.prototype.bind &&
        -1 != Function.prototype.bind.toString().indexOf("native code")
          ? pa
          : qa;
      return ra.apply(null, arguments);
    },
    sa = function (a, b) {
      function c() {}
      c.prototype = b.prototype;
      a.na = b.prototype;
      a.prototype = new c();
      a.prototype.constructor = a;
      a.A = function (d, e, f) {
        for (
          var g = Array(arguments.length - 2), h = 2;
          h < arguments.length;
          h++
        )
          g[h - 2] = arguments[h];
        return b.prototype[e].apply(d, g);
      };
    },
    ta = function (a) {
      return a;
    },
    ua = function (a) {
      var b = null,
        c = q.trustedTypes;
      if (!c || !c.createPolicy) return b;
      try {
        b = c.createPolicy(a, {
          createHTML: ta,
          createScript: ta,
          createScriptURL: ta,
        });
      } catch (d) {
        q.console && q.console.error(d.message);
      }
      return b;
    };
  function va(a) {
    if (Error.captureStackTrace) Error.captureStackTrace(this, va);
    else {
      var b = Error().stack;
      b && (this.stack = b);
    }
    a && (this.message = String(a));
  }
  sa(va, Error);
  va.prototype.name = "CustomError";
  var wa;
  var xa = function (a, b) {
    a = a.split("%s");
    for (var c = "", d = a.length - 1, e = 0; e < d; e++)
      c += a[e] + (e < b.length ? b[e] : "%s");
    va.call(this, c + a[d]);
  };
  sa(xa, va);
  xa.prototype.name = "AssertionError";
  var ya = function (a, b, c, d) {
      var e = "Assertion failed";
      if (c) {
        e += ": " + c;
        var f = d;
      } else a && ((e += ": " + a), (f = b));
      throw new xa("" + e, f || []);
    },
    za = function (a, b, c) {
      a || ya("", null, b, Array.prototype.slice.call(arguments, 2));
      return a;
    },
    Aa = function (a, b) {
      throw new xa(
        "Failure" + (a ? ": " + a : ""),
        Array.prototype.slice.call(arguments, 1)
      );
    },
    Ba = function (a, b, c) {
      "string" !== typeof a &&
        ya(
          "Expected string but got %s: %s.",
          [ia(a), a],
          b,
          Array.prototype.slice.call(arguments, 2)
        );
    };
  var Ca = Array.prototype.forEach
    ? function (a, b) {
        za(null != a.length);
        Array.prototype.forEach.call(a, b, void 0);
      }
    : function (a, b) {
        for (
          var c = a.length, d = "string" === typeof a ? a.split("") : a, e = 0;
          e < c;
          e++
        )
          e in d && b.call(void 0, d[e], e, a);
      };
  function Da(a) {
    var b = a.length;
    if (0 < b) {
      for (var c = Array(b), d = 0; d < b; d++) c[d] = a[d];
      return c;
    }
    return [];
  }
  function Ea(a, b) {
    for (var c in a) b.call(void 0, a[c], c, a);
  }
  var Fa =
    "constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(
      " "
    );
  function Ga(a, b) {
    for (var c, d, e = 1; e < arguments.length; e++) {
      d = arguments[e];
      for (c in d) a[c] = d[c];
      for (var f = 0; f < Fa.length; f++)
        (c = Fa[f]),
          Object.prototype.hasOwnProperty.call(d, c) && (a[c] = d[c]);
    }
  }
  var Ia;
  var r = function (a, b) {
    this.R = (a === Ja && b) || "";
    this.ca = Ka;
  };
  r.prototype.D = !0;
  r.prototype.C = function () {
    return this.R;
  };
  r.prototype.toString = function () {
    return "Const{" + this.R + "}";
  };
  var La = function (a) {
      if (a instanceof r && a.constructor === r && a.ca === Ka) return a.R;
      Aa("expected object of type Const, got '" + a + "'");
      return "type_error:Const";
    },
    Ka = {},
    Ja = {};
  var Ma = /&/g,
    Na = /</g,
    Oa = />/g,
    Pa = /"/g,
    Qa = /'/g,
    Ra = /\x00/g,
    Sa = /[\x00&<>"']/,
    w = function (a, b) {
      return -1 != a.indexOf(b);
    };
  var x = function (a, b) {
    this.O = b === Ta ? a : "";
  };
  x.prototype.D = !0;
  x.prototype.C = function () {
    return this.O.toString();
  };
  x.prototype.toString = function () {
    return this.O.toString();
  };
  var Ua = function (a) {
      if (a instanceof x && a.constructor === x) return a.O;
      Aa("expected object of type SafeUrl, got '" + a + "' of type " + ia(a));
      return "type_error:SafeUrl";
    },
    Va = /^(?:(?:https?|mailto|ftp):|[^:/?#]*(?:[/?#]|$))/i,
    Wa = function (a) {
      if (a instanceof x) return a;
      a = "object" == typeof a && a.D ? a.C() : String(a);
      za(Va.test(a), "%s does not match the safe URL pattern", a) ||
        (a = "about:invalid#zClosurez");
      return new x(a, Ta);
    },
    Ta = {};
  var z;
  a: {
    var Xa = q.navigator;
    if (Xa) {
      var Ya = Xa.userAgent;
      if (Ya) {
        z = Ya;
        break a;
      }
    }
    z = "";
  }
  var A = function (a, b, c) {
    this.N = c === Za ? a : "";
  };
  A.prototype.D = !0;
  A.prototype.C = function () {
    return this.N.toString();
  };
  A.prototype.toString = function () {
    return this.N.toString();
  };
  var $a = function (a) {
      if (a instanceof A && a.constructor === A) return a.N;
      Aa("expected object of type SafeHtml, got '" + a + "' of type " + ia(a));
      return "type_error:SafeHtml";
    },
    Za = {},
    ab = new A((q.trustedTypes && q.trustedTypes.emptyHTML) || "", 0, Za);
  var bb = function (a, b) {
    a: {
      try {
        var c = a && a.ownerDocument,
          d = c && (c.defaultView || c.parentWindow);
        d = d || q;
        if (d.Element && d.Location) {
          var e = d;
          break a;
        }
      } catch (g) {}
      e = null;
    }
    if (
      e &&
      "undefined" != typeof e[b] &&
      (!a ||
        (!(a instanceof e[b]) &&
          (a instanceof e.Location || a instanceof e.Element)))
    ) {
      if (ma(a))
        try {
          var f =
            a.constructor.displayName ||
            a.constructor.name ||
            Object.prototype.toString.call(a);
        } catch (g) {
          f = "<object could not be stringified>";
        }
      else f = void 0 === a ? "undefined" : null === a ? "null" : typeof a;
      Aa(
        "Argument is not a %s (or a non-Element, non-Location mock); got: %s",
        b,
        f
      );
    }
    return a;
  };
  var cb = { MATH: !0, SCRIPT: !0, STYLE: !0, SVG: !0, TEMPLATE: !0 },
    db = (function (a) {
      var b = !1,
        c;
      return function () {
        b || ((c = a()), (b = !0));
        return c;
      };
    })(function () {
      if ("undefined" === typeof document) return !1;
      var a = document.createElement("div"),
        b = document.createElement("div");
      b.appendChild(document.createElement("div"));
      a.appendChild(b);
      if (!a.firstChild) return !1;
      b = a.firstChild.firstChild;
      a.innerHTML = $a(ab);
      return !b.parentElement;
    });
  var eb = function (a) {
    Sa.test(a) &&
      (-1 != a.indexOf("&") && (a = a.replace(Ma, "&amp;")),
      -1 != a.indexOf("<") && (a = a.replace(Na, "&lt;")),
      -1 != a.indexOf(">") && (a = a.replace(Oa, "&gt;")),
      -1 != a.indexOf('"') && (a = a.replace(Pa, "&quot;")),
      -1 != a.indexOf("'") && (a = a.replace(Qa, "&#39;")),
      -1 != a.indexOf("\x00") && (a = a.replace(Ra, "&#0;")));
    return a;
  };
  var fb = w(z, "Opera"),
    gb = w(z, "Trident") || w(z, "MSIE"),
    hb = w(z, "Edge"),
    ib =
      w(z, "Gecko") &&
      !(w(z.toLowerCase(), "webkit") && !w(z, "Edge")) &&
      !(w(z, "Trident") || w(z, "MSIE")) &&
      !w(z, "Edge"),
    jb = w(z.toLowerCase(), "webkit") && !w(z, "Edge"),
    lb = function () {
      var a = q.document;
      return a ? a.documentMode : void 0;
    },
    mb;
  a: {
    var nb = "",
      ob = (function () {
        var a = z;
        if (ib) return /rv:([^\);]+)(\)|;)/.exec(a);
        if (hb) return /Edge\/([\d\.]+)/.exec(a);
        if (gb) return /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);
        if (jb) return /WebKit\/(\S+)/.exec(a);
        if (fb) return /(?:Version)[ \/]?(\S+)/.exec(a);
      })();
    ob && (nb = ob ? ob[1] : "");
    if (gb) {
      var pb = lb();
      if (null != pb && pb > parseFloat(nb)) {
        mb = String(pb);
        break a;
      }
    }
    mb = nb;
  }
  var qb = mb,
    rb;
  if (q.document && gb) {
    var sb = lb();
    rb = sb ? sb : parseInt(qb, 10) || void 0;
  } else rb = void 0;
  var tb = rb;
  var ub;
  (ub = !gb) || (ub = 9 <= Number(tb));
  var vb = ub;
  var xb = function (a, b) {
      Ea(b, function (c, d) {
        c && "object" == typeof c && c.D && (c = c.C());
        "style" == d
          ? (a.style.cssText = c)
          : "class" == d
          ? (a.className = c)
          : "for" == d
          ? (a.htmlFor = c)
          : wb.hasOwnProperty(d)
          ? a.setAttribute(wb[d], c)
          : 0 == d.lastIndexOf("aria-", 0) || 0 == d.lastIndexOf("data-", 0)
          ? a.setAttribute(d, c)
          : (a[d] = c);
      });
    },
    wb = {
      cellpadding: "cellPadding",
      cellspacing: "cellSpacing",
      colspan: "colSpan",
      frameborder: "frameBorder",
      height: "height",
      maxlength: "maxLength",
      nonce: "nonce",
      role: "role",
      rowspan: "rowSpan",
      type: "type",
      usemap: "useMap",
      valign: "vAlign",
      width: "width",
    },
    yb = function (a, b, c, d) {
      function e(h) {
        h && b.appendChild("string" === typeof h ? a.createTextNode(h) : h);
      }
      for (; d < c.length; d++) {
        var f = c[d];
        if (!la(f) || (ma(f) && 0 < f.nodeType)) e(f);
        else {
          a: {
            if (f && "number" == typeof f.length) {
              if (ma(f)) {
                var g =
                  "function" == typeof f.item || "string" == typeof f.item;
                break a;
              }
              if ("function" === typeof f) {
                g = "function" == typeof f.item;
                break a;
              }
            }
            g = !1;
          }
          Ca(g ? Da(f) : f, e);
        }
      }
    },
    zb = function (a, b) {
      b = String(b);
      "application/xhtml+xml" === a.contentType && (b = b.toLowerCase());
      return a.createElement(b);
    },
    Ab = function (a) {
      za(a, "Node cannot be null or undefined.");
      return 9 == a.nodeType ? a : a.ownerDocument || a.document;
    },
    Bb = function (a) {
      this.B = a || q.document || document;
    };
  n = Bb.prototype;
  n.getElementsByTagName = function (a, b) {
    return (b || this.B).getElementsByTagName(String(a));
  };
  n.fa = function (a, b, c) {
    var d = this.B,
      e = arguments,
      f = String(e[0]),
      g = e[1];
    if (!vb && g && (g.name || g.type)) {
      f = ["<", f];
      g.name && f.push(' name="', eb(g.name), '"');
      if (g.type) {
        f.push(' type="', eb(g.type), '"');
        var h = {};
        Ga(h, g);
        delete h.type;
        g = h;
      }
      f.push(">");
      f = f.join("");
    }
    f = zb(d, f);
    g &&
      ("string" === typeof g
        ? (f.className = g)
        : Array.isArray(g)
        ? (f.className = g.join(" "))
        : xb(f, g));
    2 < e.length && yb(d, f, e, 2);
    return f;
  };
  n.createElement = function (a) {
    return zb(this.B, a);
  };
  n.createTextNode = function (a) {
    return this.B.createTextNode(String(a));
  };
  n.appendChild = function (a, b) {
    za(
      null != a && null != b,
      "goog.dom.appendChild expects non-null arguments"
    );
    a.appendChild(b);
  };
  n.append = function (a, b) {
    yb(Ab(a), a, arguments, 1);
  };
  n.canHaveChildren = function (a) {
    if (1 != a.nodeType) return !1;
    switch (a.tagName) {
      case "APPLET":
      case "AREA":
      case "BASE":
      case "BR":
      case "COL":
      case "COMMAND":
      case "EMBED":
      case "FRAME":
      case "HR":
      case "IMG":
      case "INPUT":
      case "IFRAME":
      case "ISINDEX":
      case "KEYGEN":
      case "LINK":
      case "NOFRAMES":
      case "NOSCRIPT":
      case "META":
      case "OBJECT":
      case "PARAM":
      case "SCRIPT":
      case "SOURCE":
      case "STYLE":
      case "TRACK":
      case "WBR":
        return !1;
    }
    return !0;
  };
  n.removeNode = function (a) {
    return a && a.parentNode ? a.parentNode.removeChild(a) : null;
  };
  n.contains = function (a, b) {
    if (!a || !b) return !1;
    if (a.contains && 1 == b.nodeType) return a == b || a.contains(b);
    if ("undefined" != typeof a.compareDocumentPosition)
      return a == b || !!(a.compareDocumentPosition(b) & 16);
    for (; b && a != b; ) b = b.parentNode;
    return b == a;
  }; /*
  gapi.loader.OBJECT_CREATE_TEST_OVERRIDE &&*/
  var B = window,
    C = document,
    Cb = B.location,
    Db = function () {},
    Eb = /\[native code\]/,
    D = function (a, b, c) {
      return (a[b] = a[b] || c);
    },
    Fb = function (a) {
      for (var b = 0; b < this.length; b++) if (this[b] === a) return b;
      return -1;
    },
    Gb = function (a) {
      a = a.sort();
      for (var b = [], c = void 0, d = 0; d < a.length; d++) {
        var e = a[d];
        e != c && b.push(e);
        c = e;
      }
      return b;
    },
    Hb = /&/g,
    Ib = /</g,
    Jb = />/g,
    Kb = /"/g,
    Lb = /'/g,
    Mb = function (a) {
      return String(a)
        .replace(Hb, "&amp;")
        .replace(Ib, "&lt;")
        .replace(Jb, "&gt;")
        .replace(Kb, "&quot;")
        .replace(Lb, "&#39;");
    },
    E = function () {
      var a;
      if ((a = Object.create) && Eb.test(a)) a = a(null);
      else {
        a = {};
        for (var b in a) a[b] = void 0;
      }
      return a;
    },
    F = function (a, b) {
      return Object.prototype.hasOwnProperty.call(a, b);
    },
    Nb = function (a) {
      if (Eb.test(Object.keys)) return Object.keys(a);
      var b = [],
        c;
      for (c in a) F(a, c) && b.push(c);
      return b;
    },
    G = function (a, b) {
      a = a || {};
      for (var c in a) F(a, c) && (b[c] = a[c]);
    },
    Ob = function (a) {
      return function () {
        B.setTimeout(a, 0);
      };
    },
    H = function (a, b) {
      if (!a) throw Error(b || "");
    },
    I = D(B, "gapi", {});
  var J = function (a, b, c) {
      var d = new RegExp("([#].*&|[#])" + b + "=([^&#]*)", "g");
      b = new RegExp("([?#].*&|[?#])" + b + "=([^&#]*)", "g");
      if ((a = a && (d.exec(a) || b.exec(a))))
        try {
          c = decodeURIComponent(a[2]);
        } catch (e) {}
      return c;
    },
    Pb = new RegExp(
      /^/.source +
        /([a-zA-Z][-+.a-zA-Z0-9]*:)?/.source +
        /(\/\/[^\/?#]*)?/.source +
        /([^?#]*)?/.source +
        /(\?([^#]*))?/.source +
        /(#((#|[^#])*))?/.source +
        /$/.source
    ),
    Qb = /[\ud800-\udbff][\udc00-\udfff]|[^!-~]/g,
    Rb = new RegExp(
      /(%([^0-9a-fA-F%]|[0-9a-fA-F]([^0-9a-fA-F%])?)?)*/.source +
        /%($|[^0-9a-fA-F]|[0-9a-fA-F]($|[^0-9a-fA-F]))/.source,
      "g"
    ),
    Sb = /%([a-f]|[0-9a-fA-F][a-f])/g,
    Tb = /^(https?|ftp|file|chrome-extension):$/i,
    Ub = function (a) {
      a = String(a);
      a = a
        .replace(Qb, function (e) {
          try {
            return encodeURIComponent(e);
          } catch (f) {
            return encodeURIComponent(e.replace(/^[^%]+$/g, "\ufffd"));
          }
        })
        .replace(Rb, function (e) {
          return e.replace(/%/g, "%25");
        })
        .replace(Sb, function (e) {
          return e.toUpperCase();
        });
      a = a.match(Pb) || [];
      var b = E(),
        c = function (e) {
          return e
            .replace(/\\/g, "%5C")
            .replace(/\^/g, "%5E")
            .replace(/`/g, "%60")
            .replace(/\{/g, "%7B")
            .replace(/\|/g, "%7C")
            .replace(/\}/g, "%7D");
        },
        d = !!(a[1] || "").match(Tb);
      b.A = c((a[1] || "") + (a[2] || "") + (a[3] || (a[2] && d ? "/" : "")));
      d = function (e) {
        return c(e.replace(/\?/g, "%3F").replace(/#/g, "%23"));
      };
      b.query = a[5] ? [d(a[5])] : [];
      b.i = a[7] ? [d(a[7])] : [];
      return b;
    },
    Vb = function (a) {
      return (
        a.A +
        (0 < a.query.length ? "?" + a.query.join("&") : "") +
        (0 < a.i.length ? "#" + a.i.join("&") : "")
      );
    },
    Wb = function (a, b) {
      var c = [];
      if (a)
        for (var d in a)
          if (F(a, d) && null != a[d]) {
            var e = b ? b(a[d]) : a[d];
            c.push(encodeURIComponent(d) + "=" + encodeURIComponent(e));
          }
      return c;
    },
    Xb = function (a, b, c, d) {
      a = Ub(a);
      a.query.push.apply(a.query, Wb(b, d));
      a.i.push.apply(a.i, Wb(c, d));
      return Vb(a);
    },
    Yb = new RegExp(
      /\/?\??#?/.source +
        "(" +
        /[\/?#]/i.source +
        "|" +
        /[\uD800-\uDBFF]/i.source +
        "|" +
        /%[c-f][0-9a-f](%[89ab][0-9a-f]){0,2}(%[89ab]?)?/i.source +
        "|" +
        /%[0-9a-f]?/i.source +
        ")$",
      "i"
    ),
    Zb = function (a, b) {
      var c = Ub(b);
      b = c.A;
      c.query.length && (b += "?" + c.query.join(""));
      c.i.length && (b += "#" + c.i.join(""));
      var d = "";
      2e3 < b.length &&
        ((d = b),
        (b = b.substr(0, 2e3)),
        (b = b.replace(Yb, "")),
        (d = d.substr(b.length)));
      var e = a.createElement("div");
      a = a.createElement("a");
      c = Ub(b);
      b = c.A;
      c.query.length && (b += "?" + c.query.join(""));
      c.i.length && (b += "#" + c.i.join(""));
      b = new x(b, Ta);
      bb(a, "HTMLAnchorElement");
      b = b instanceof x ? b : Wa(b);
      a.href = Ua(b);
      e.appendChild(a);
      b = e.innerHTML;
      c = new r(Ja, "Assignment to self.");
      Ba(La(c), "must provide justification");
      za(!/^[\s\xa0]*$/.test(La(c)), "must provide non-empty justification");
      void 0 === Ia && (Ia = ua("gapi#html"));
      b = (c = Ia) ? c.createHTML(b) : b;
      b = new A(b, null, Za);
      if (e.tagName && cb[e.tagName.toUpperCase()])
        throw Error(
          "goog.dom.safe.setInnerHtml cannot be used to set content of " +
            e.tagName +
            "."
        );
      if (db()) for (; e.lastChild; ) e.removeChild(e.lastChild);
      e.innerHTML = $a(b);
      b = String(e.firstChild.href);
      e.parentNode && e.parentNode.removeChild(e);
      c = Ub(b + d);
      d = c.A;
      c.query.length && (d += "?" + c.query.join(""));
      c.i.length && (d += "#" + c.i.join(""));
      return d;
    },
    $b = /^https?:\/\/[^\/%\\?#\s]+\/[^\s]*$/i;
  var ac;
  var bc = function (a, b, c, d) {
      if (B[c + "EventListener"]) B[c + "EventListener"](a, b, !1);
      else if (B[d + "tachEvent"]) B[d + "tachEvent"]("on" + a, b);
    },
    cc = function () {
      var a = C.readyState;
      return (
        "complete" === a ||
        ("interactive" === a && -1 == navigator.userAgent.indexOf("MSIE"))
      );
    },
    fc = function (a) {
      var b = dc;
      if (!cc())
        try {
          b();
        } catch (c) {}
      ec(a);
    },
    ec = function (a) {
      if (cc()) a();
      else {
        var b = !1,
          c = function () {
            if (!b) return (b = !0), a.apply(this, arguments);
          };
        B.addEventListener
          ? (B.addEventListener("load", c, !1),
            B.addEventListener("DOMContentLoaded", c, !1))
          : B.attachEvent &&
            (B.attachEvent("onreadystatechange", function () {
              cc() && c.apply(this, arguments);
            }),
            B.attachEvent("onload", c));
      }
    },
    gc = function (a) {
      for (; a.firstChild; ) a.removeChild(a.firstChild);
    },
    hc = { button: !0, div: !0, span: !0 };
  var L;
  L = D(B, "___jsl", E());
  D(L, "I", 0);
  D(L, "hel", 10);
  var ic = function (a) {
      return L.dpo ? L.h : J(a, "jsh", L.h);
    },
    jc = function (a) {
      var b = D(L, "sws", []);
      b.push.apply(b, a);
    },
    kc = function (a) {
      return D(L, "watt", E())[a];
    },
    lc = function (a) {
      var b = D(L, "PQ", []);
      L.PQ = [];
      var c = b.length;
      if (0 === c) a();
      else
        for (
          var d = 0,
            e = function () {
              ++d === c && a();
            },
            f = 0;
          f < c;
          f++
        )
          b[f](e);
    },
    mc = function (a) {
      return D(D(L, "H", E()), a, E());
    };
  var nc = D(L, "perf", E()),
    oc = D(nc, "g", E()),
    pc = D(nc, "i", E());
  D(nc, "r", []);
  E();
  E();
  var qc = function (a, b, c) {
      var d = nc.r;
      "function" === typeof d ? d(a, b, c) : d.push([a, b, c]);
    },
    N = function (a, b, c) {
      oc[a] = (!b && oc[a]) || c || new Date().getTime();
      qc(a);
    },
    sc = function (a, b, c) {
      b &&
        0 < b.length &&
        ((b = rc(b)),
        c && 0 < c.length && (b += "___" + rc(c)),
        28 < b.length && (b = b.substr(0, 28) + (b.length - 28)),
        (c = b),
        (b = D(pc, "_p", E())),
        (D(b, c, E())[a] = new Date().getTime()),
        qc(a, "_p", c));
    },
    rc = function (a) {
      return a
        .join("__")
        .replace(/\./g, "_")
        .replace(/\-/g, "_")
        .replace(/,/g, "_");
    };
  var tc = E(),
    uc = [],
    O = function (a) {
      throw Error("Bad hint" + (a ? ": " + a : ""));
    };
  uc.push([
    "jsl",
    function (a) {
      for (var b in a)
        if (F(a, b)) {
          var c = a[b];
          "object" == typeof c ? (L[b] = D(L, b, []).concat(c)) : D(L, b, c);
        }
      if ((b = a.u))
        (a = D(L, "us", [])),
          a.push(b),
          (b = /^https:(.*)$/.exec(b)) && a.push("http:" + b[1]);
    },
  ]);
  var vc = /^(\/[a-zA-Z0-9_\-]+)+$/,
    wc = [/\/amp\//, /\/amp$/, /^\/amp$/],
    xc = /^[a-zA-Z0-9\-_\.,!]+$/,
    yc = /^gapi\.loaded_[0-9]+$/,
    zc = /^[a-zA-Z0-9,._-]+$/,
    Dc = function (a, b, c, d, e) {
      var f = a.split(";"),
        g = f.shift(),
        h = tc[g],
        k = null;
      h ? (k = h(f, b, c, d)) : O("no hint processor for: " + g);
      k || O("failed to generate load url");
      b = k;
      c = b.match(Ac);
      ((d = b.match(Bc)) &&
        1 === d.length &&
        Cc.test(b) &&
        c &&
        1 === c.length) ||
        O("failed sanity: " + a);
      try {
        if (e && 0 < e.length) {
          b = a = 0;
          for (c = {}; b < e.length; ) {
            var l = e[b++];
            d = void 0;
            d = ma(l)
              ? "o" +
                ((Object.prototype.hasOwnProperty.call(l, na) && l[na]) ||
                  (l[na] = ++oa))
              : (typeof l).charAt(0) + l;
            Object.prototype.hasOwnProperty.call(c, d) ||
              ((c[d] = !0), (e[a++] = l));
          }
          e.length = a;
          k = k + "?le=" + e.join(",");
        }
      } catch (m) {}
      return k;
    },
    Gc = function (a, b, c, d) {
      a = Ec(a);
      yc.test(c) || O("invalid_callback");
      b = Fc(b);
      d = d && d.length ? Fc(d) : null;
      var e = function (f) {
        return encodeURIComponent(f).replace(/%2C/g, ",");
      };
      return [
        encodeURIComponent(a.pathPrefix)
          .replace(/%2C/g, ",")
          .replace(/%2F/g, "/"),
        "/k=",
        e(a.version),
        "/m=",
        e(b),
        d ? "/exm=" + e(d) : "",
        "/rt=j/sv=1/d=1/ed=1",
        a.T ? "/am=" + e(a.T) : "",
        a.Z ? "/rs=" + e(a.Z) : "",
        a.aa ? "/t=" + e(a.aa) : "",
        "/cb=",
        e(c),
      ].join("");
    },
    Ec = function (a) {
      "/" !== a.charAt(0) && O("relative path");
      for (var b = a.substring(1).split("/"), c = []; b.length; ) {
        a = b.shift();
        if (!a.length || 0 == a.indexOf(".")) O("empty/relative directory");
        else if (0 < a.indexOf("=")) {
          b.unshift(a);
          break;
        }
        c.push(a);
      }
      a = {};
      for (var d = 0, e = b.length; d < e; ++d) {
        var f = b[d].split("="),
          g = decodeURIComponent(f[0]),
          h = decodeURIComponent(f[1]);
        2 == f.length && g && h && (a[g] = a[g] || h);
      }
      b = "/" + c.join("/");
      vc.test(b) || O("invalid_prefix");
      c = 0;
      for (d = wc.length; c < d; ++c) wc[c].test(b) && O("invalid_prefix");
      c = Hc(a, "k", !0);
      d = Hc(a, "am");
      e = Hc(a, "rs");
      a = Hc(a, "t");
      return { pathPrefix: b, version: c, T: d, Z: e, aa: a };
    },
    Fc = function (a) {
      for (var b = [], c = 0, d = a.length; c < d; ++c) {
        var e = a[c].replace(/\./g, "_").replace(/-/g, "_");
        zc.test(e) && b.push(e);
      }
      return b.join(",");
    },
    Hc = function (a, b, c) {
      a = a[b];
      !a && c && O("missing: " + b);
      if (a) {
        if (xc.test(a)) return a;
        O("invalid: " + b);
      }
      return null;
    },
    Cc =
      /^https?:\/\/[a-z0-9_.-]+\.google(rs)?\.com(:\d+)?\/[a-zA-Z0-9_.,!=\-\/]+$/,
    Bc = /\/cb=/g,
    Ac = /\/\//g,
    Ic = function () {
      var a = ic(Cb.href);
      if (!a) throw Error("Bad hint");
      return a;
    };
  tc.m = function (a, b, c, d) {
    (a = a[0]) || O("missing_hint");
    return "https://apis.google.com" + Gc(a, b, c, d);
  };
  var Jc = decodeURI("%73cript"),
    Kc = /^[-+_0-9\/A-Za-z]+={0,2}$/,
    Lc = function (a, b) {
      for (var c = [], d = 0; d < a.length; ++d) {
        var e = a[d];
        e && 0 > Fb.call(b, e) && c.push(e);
      }
      return c;
    },
    Mc = function () {
      var a = L.nonce;
      return void 0 !== a
        ? a && a === String(a) && a.match(Kc)
          ? a
          : (L.nonce = null)
        : C.querySelector
        ? (a = C.querySelector("script[nonce]"))
          ? ((a = a.nonce || a.getAttribute("nonce") || ""),
            a && a === String(a) && a.match(Kc)
              ? (L.nonce = a)
              : (L.nonce = null))
          : null
        : null;
    },
    Pc = function (a) {
      if ("loading" != C.readyState) Nc(a);
      else {
        var b = Mc(),
          c = "";
        null !== b && (c = ' nonce="' + b + '"');
        a = "<" + Jc + ' src="' + encodeURI(a) + '"' + c + "></" + Jc + ">";
        C.write(Oc ? Oc.createHTML(a) : a);
      }
    },
    Nc = function (a) {
      var b = C.createElement(Jc);
      b.setAttribute("src", Oc ? Oc.createScriptURL(a) : a);
      a = Mc();
      null !== a && b.setAttribute("nonce", a);
      b.async = "true";
      (a = C.getElementsByTagName(Jc)[0])
        ? a.parentNode.insertBefore(b, a)
        : (C.head || C.body || C.documentElement).appendChild(b);
    },
    Qc = function (a, b) {
      var c = b && b._c;
      if (c)
        for (var d = 0; d < uc.length; d++) {
          var e = uc[d][0],
            f = uc[d][1];
          f && F(c, e) && f(c[e], a, b);
        }
    },
    Sc = function (a, b, c) {
      Rc(function () {
        var d = b === ic(Cb.href) ? D(I, "_", E()) : E();
        d = D(mc(b), "_", d);
        a(d);
      }, c);
    },
    Uc = function (a, b) {
      var c = b || {};
      "function" == typeof b && ((c = {}), (c.callback = b));
      Qc(a, c);
      b = a ? a.split(":") : [];
      var d = c.h || Ic(),
        e = D(L, "ah", E());
      if (e["::"] && b.length) {
        a = [];
        for (var f = null; (f = b.shift()); ) {
          var g = f.split(".");
          g = e[f] || e[(g[1] && "ns:" + g[0]) || ""] || d;
          var h = (a.length && a[a.length - 1]) || null,
            k = h;
          (h && h.hint == g) || ((k = { hint: g, features: [] }), a.push(k));
          k.features.push(f);
        }
        var l = a.length;
        if (1 < l) {
          var m = c.callback;
          m &&
            (c.callback = function () {
              0 == --l && m();
            });
        }
        for (; (b = a.shift()); ) Tc(b.features, c, b.hint);
      } else Tc(b || [], c, d);
    },
    Tc = function (a, b, c) {
      a = Gb(a) || [];
      var d = b.callback,
        e = b.config,
        f = b.timeout,
        g = b.ontimeout,
        h = b.onerror,
        k = void 0;
      "function" == typeof h && (k = h);
      var l = null,
        m = !1;
      if ((f && !g) || (!f && g))
        throw "Timeout requires both the timeout parameter and ontimeout parameter to be set";
      h = D(mc(c), "r", []).sort();
      var t = D(mc(c), "L", []).sort(),
        u = L.le,
        p = [].concat(h),
        K = function (S, ja) {
          if (m) return 0;
          B.clearTimeout(l);
          t.push.apply(t, y);
          var ka = ((I || {}).config || {}).update;
          ka ? ka(e) : e && D(L, "cu", []).push(e);
          if (ja) {
            sc("me0", S, p);
            try {
              Sc(ja, c, k);
            } finally {
              sc("me1", S, p);
            }
          }
          return 1;
        };
      0 < f &&
        (l = B.setTimeout(function () {
          m = !0;
          g();
        }, f));
      var y = Lc(a, t);
      if (y.length) {
        y = Lc(a, h);
        var v = D(L, "CP", []),
          M = v.length;
        v[M] = function (S) {
          if (!S) return 0;
          sc("ml1", y, p);
          var ja = function (Ha) {
              v[M] = null;
              K(y, S) &&
                lc(function () {
                  d && d();
                  Ha();
                });
            },
            ka = function () {
              var Ha = v[M + 1];
              Ha && Ha();
            };
          0 < M && v[M - 1]
            ? (v[M] = function () {
                ja(ka);
              })
            : ja(ka);
        };
        if (y.length) {
          var kb = "loaded_" + L.I++;
          I[kb] = function (S) {
            v[M](S);
            I[kb] = null;
          };
          a = Dc(c, y, "gapi." + kb, h, u);
          h.push.apply(h, y);
          sc("ml0", y, p);
          b.sync || B.___gapisync ? Pc(a) : Nc(a);
        } else v[M](Db);
      } else K(y) && d && d();
    },
    Oc = ua("gapi#gapi");
  var Rc = function (a, b) {
    if (L.hee && 0 < L.hel)
      try {
        return a();
      } catch (c) {
        b && b(c),
          L.hel--,
          Uc("debug_error", function () {
            try {
              window.___jsl.hefn(c);
            } catch (d) {
              throw c;
            }
          });
      }
    else
      try {
        return a();
      } catch (c) {
        throw (b && b(c), c);
      }
  };
  I.load = function (a, b) {
    return Rc(function () {
      return Uc(a, b);
    });
  };
  var Vc = function (a) {
      var b = (window.___jsl = window.___jsl || {});
      b[a] = b[a] || [];
      return b[a];
    },
    Wc = function (a) {
      var b = (window.___jsl = window.___jsl || {});
      b.cfg = (!a && b.cfg) || {};
      return b.cfg;
    },
    Xc = function (a) {
      return "object" === typeof a && /\[native code\]/.test(a.push);
    },
    P = function (a, b, c) {
      if (b && "object" === typeof b)
        for (var d in b)
          !Object.prototype.hasOwnProperty.call(b, d) ||
            (c && "___goc" === d && "undefined" === typeof b[d]) ||
            (a[d] &&
            b[d] &&
            "object" === typeof a[d] &&
            "object" === typeof b[d] &&
            !Xc(a[d]) &&
            !Xc(b[d])
              ? P(a[d], b[d])
              : b[d] && "object" === typeof b[d]
              ? ((a[d] = Xc(b[d]) ? [] : {}), P(a[d], b[d]))
              : (a[d] = b[d]));
    },
    Yc = function (a) {
      if (a && !/^\s+$/.test(a)) {
        for (; 0 == a.charCodeAt(a.length - 1); )
          a = a.substring(0, a.length - 1);
        try {
          var b = window.JSON.parse(a);
        } catch (c) {}
        if ("object" === typeof b) return b;
        try {
          b = new Function("return (" + a + "\n)")();
        } catch (c) {}
        if ("object" === typeof b) return b;
        try {
          b = new Function("return ({" + a + "\n})")();
        } catch (c) {}
        return "object" === typeof b ? b : {};
      }
    },
    Zc = function (a, b) {
      var c = { ___goc: void 0 };
      a.length &&
        a[a.length - 1] &&
        Object.hasOwnProperty.call(a[a.length - 1], "___goc") &&
        "undefined" === typeof a[a.length - 1].___goc &&
        (c = a.pop());
      P(c, b);
      a.push(c);
    },
    $c = function (a) {
      Wc(!0);
      var b = window.___gcfg,
        c = Vc("cu"),
        d = window.___gu;
      b && b !== d && (Zc(c, b), (window.___gu = b));
      b = Vc("cu");
      var e = document.scripts || document.getElementsByTagName("script") || [];
      d = [];
      var f = [];
      f.push.apply(f, Vc("us"));
      for (var g = 0; g < e.length; ++g)
        for (var h = e[g], k = 0; k < f.length; ++k)
          h.src && 0 == h.src.indexOf(f[k]) && d.push(h);
      0 == d.length &&
        0 < e.length &&
        e[e.length - 1].src &&
        d.push(e[e.length - 1]);
      for (e = 0; e < d.length; ++e)
        d[e].getAttribute("gapi_processed") ||
          (d[e].setAttribute("gapi_processed", !0),
          (f = d[e])
            ? ((g = f.nodeType),
              (f = 3 == g || 4 == g ? f.nodeValue : f.textContent || ""))
            : (f = void 0),
          (f = Yc(f)) && b.push(f));
      a && Zc(c, a);
      d = Vc("cd");
      a = 0;
      for (b = d.length; a < b; ++a) P(Wc(), d[a], !0);
      d = Vc("ci");
      a = 0;
      for (b = d.length; a < b; ++a) P(Wc(), d[a], !0);
      a = 0;
      for (b = c.length; a < b; ++a) P(Wc(), c[a], !0);
    },
    Q = function (a) {
      var b = Wc();
      if (!a) return b;
      a = a.split("/");
      for (var c = 0, d = a.length; b && "object" === typeof b && c < d; ++c)
        b = b[a[c]];
      return c === a.length && void 0 !== b ? b : void 0;
    },
    ad = function (a, b) {
      var c;
      if ("string" === typeof a) {
        var d = (c = {});
        a = a.split("/");
        for (var e = 0, f = a.length; e < f - 1; ++e) {
          var g = {};
          d = d[a[e]] = g;
        }
        d[a[e]] = b;
      } else c = a;
      $c(c);
    };
  var bd = function () {
    var a = window.__GOOGLEAPIS;
    a &&
      (a.googleapis &&
        !a["googleapis.config"] &&
        (a["googleapis.config"] = a.googleapis),
      D(L, "ci", []).push(a),
      (window.__GOOGLEAPIS = void 0));
  };
  var cd = {
      callback: 1,
      clientid: 1,
      cookiepolicy: 1,
      openidrealm: -1,
      includegrantedscopes: -1,
      requestvisibleactions: 1,
      scope: 1,
    },
    dd = !1,
    ed = E(),
    fd = function () {
      if (!dd) {
        for (
          var a = document.getElementsByTagName("meta"), b = 0;
          b < a.length;
          ++b
        ) {
          var c = a[b].name.toLowerCase();
          if (0 == c.lastIndexOf("google-signin-", 0)) {
            c = c.substring(14);
            var d = a[b].content;
            cd[c] && d && (ed[c] = d);
          }
        }
        if (window.self !== window.top) {
          a = document.location.toString();
          for (var e in cd) 0 < cd[e] && (b = J(a, e, "")) && (ed[e] = b);
        }
        dd = !0;
      }
      e = E();
      G(ed, e);
      return e;
    },
    gd = function (a) {
      return !!(a.clientid && a.scope && a.callback);
    };
  var hd = window.console,
    id = function (a) {
      hd && hd.log && hd.log(a);
    },
    jd = function () {},
    kd = ["gapi", "logutil", "enableDebugLogging"],
    R = q;
  kd[0] in R ||
    "undefined" == typeof R.execScript ||
    R.execScript("var " + kd[0]);
  for (var T; kd.length && (T = kd.shift()); )
    kd.length || void 0 === jd
      ? (R = R[T] && R[T] !== Object.prototype[T] ? R[T] : (R[T] = {}))
      : (R[T] = jd);
  var ld = function () {
      return !!L.oa;
    },
    md = function () {};
  var U = D(L, "rw", E()),
    nd = function (a) {
      for (var b in U) a(U[b]);
    },
    od = function (a, b) {
      (a = U[a]) && a.state < b && (a.state = b);
    };
  var V = function (a) {
    var b = (window.___jsl = window.___jsl || {});
    b.cfg = b.cfg || {};
    b = b.cfg;
    if (!a) return b;
    a = a.split("/");
    for (var c = 0, d = a.length; b && "object" === typeof b && c < d; ++c)
      b = b[a[c]];
    return c === a.length && void 0 !== b ? b : void 0;
  };
  var pd =
      /^https?:\/\/(?:\w|[\-\.])+\.google\.(?:\w|[\-:\.])+(?:\/[^\?#]*)?\/u\/(\d)\//,
    qd =
      /^https?:\/\/(?:\w|[\-\.])+\.google\.(?:\w|[\-:\.])+(?:\/[^\?#]*)?\/b\/(\d{10,21})\//,
    rd = function (a) {
      var b = V("googleapis.config/sessionIndex");
      "string" === typeof b && 254 < b.length && (b = null);
      null == b && (b = window.__X_GOOG_AUTHUSER);
      "string" === typeof b && 254 < b.length && (b = null);
      if (null == b) {
        var c = window.google;
        c && (b = c.authuser);
      }
      "string" === typeof b && 254 < b.length && (b = null);
      null == b &&
        ((a = a || window.location.href),
        (b = J(a, "authuser") || null),
        null == b && (b = (b = a.match(pd)) ? b[1] : null));
      if (null == b) return null;
      b = String(b);
      254 < b.length && (b = null);
      return b;
    },
    sd = function (a) {
      var b = V("googleapis.config/sessionDelegate");
      "string" === typeof b && 21 < b.length && (b = null);
      null == b &&
        (b = (a = (a || window.location.href).match(qd)) ? a[1] : null);
      if (null == b) return null;
      b = String(b);
      21 < b.length && (b = null);
      return b;
    };
  var td,
    W,
    ud = void 0,
    X = function (a) {
      try {
        return q.JSON.parse.call(q.JSON, a);
      } catch (b) {
        return !1;
      }
    },
    Y = function (a) {
      return Object.prototype.toString.call(a);
    },
    vd = Y(0),
    wd = Y(new Date(0)),
    xd = Y(!0),
    yd = Y(""),
    zd = Y({}),
    Ad = Y([]),
    Bd = function (a, b) {
      if (b)
        for (var c = 0, d = b.length; c < d; ++c)
          if (a === b[c])
            throw new TypeError("Converting circular structure to JSON");
      d = typeof a;
      if ("undefined" !== d) {
        c = Array.prototype.slice.call(b || [], 0);
        c[c.length] = a;
        b = [];
        var e = Y(a);
        if (
          null != a &&
          "function" === typeof a.toJSON &&
          (Object.prototype.hasOwnProperty.call(a, "toJSON") ||
            ((e !== Ad ||
              (a.constructor !== Array && a.constructor !== Object)) &&
              (e !== zd ||
                (a.constructor !== Array && a.constructor !== Object)) &&
              e !== yd &&
              e !== vd &&
              e !== xd &&
              e !== wd))
        )
          return Bd(a.toJSON.call(a), c);
        if (null == a) b[b.length] = "null";
        else if (e === vd)
          (a = Number(a)),
            isNaN(a) || isNaN(a - a)
              ? (a = "null")
              : -0 === a && 0 > 1 / a && (a = "-0"),
            (b[b.length] = String(a));
        else if (e === xd) b[b.length] = String(!!Number(a));
        else {
          if (e === wd) return Bd(a.toISOString.call(a), c);
          if (e === Ad && Y(a.length) === vd) {
            b[b.length] = "[";
            var f = 0;
            for (d = Number(a.length) >> 0; f < d; ++f)
              f && (b[b.length] = ","), (b[b.length] = Bd(a[f], c) || "null");
            b[b.length] = "]";
          } else if (e == yd && Y(a.length) === vd) {
            b[b.length] = '"';
            f = 0;
            for (c = Number(a.length) >> 0; f < c; ++f)
              (d = String.prototype.charAt.call(a, f)),
                (e = String.prototype.charCodeAt.call(a, f)),
                (b[b.length] =
                  "\b" === d
                    ? "\\b"
                    : "\f" === d
                    ? "\\f"
                    : "\n" === d
                    ? "\\n"
                    : "\r" === d
                    ? "\\r"
                    : "\t" === d
                    ? "\\t"
                    : "\\" === d || '"' === d
                    ? "\\" + d
                    : 31 >= e
                    ? "\\u" + (e + 65536).toString(16).substr(1)
                    : 32 <= e && 65535 >= e
                    ? d
                    : "\ufffd");
            b[b.length] = '"';
          } else if ("object" === d) {
            b[b.length] = "{";
            d = 0;
            for (f in a)
              Object.prototype.hasOwnProperty.call(a, f) &&
                ((e = Bd(a[f], c)),
                void 0 !== e &&
                  (d++ && (b[b.length] = ","),
                  (b[b.length] = Bd(f)),
                  (b[b.length] = ":"),
                  (b[b.length] = e)));
            b[b.length] = "}";
          } else return;
        }
        return b.join("");
      }
    },
    Cd = /[\0-\x07\x0b\x0e-\x1f]/,
    Dd = /^([^"]*"([^\\"]|\\.)*")*[^"]*"([^"\\]|\\.)*[\0-\x1f]/,
    Ed = /^([^"]*"([^\\"]|\\.)*")*[^"]*"([^"\\]|\\.)*\\[^\\\/"bfnrtu]/,
    Fd =
      /^([^"]*"([^\\"]|\\.)*")*[^"]*"([^"\\]|\\.)*\\u([0-9a-fA-F]{0,3}[^0-9a-fA-F])/,
    Gd = /"([^\0-\x1f\\"]|\\[\\\/"bfnrt]|\\u[0-9a-fA-F]{4})*"/g,
    Hd = /-?(0|[1-9][0-9]*)(\.[0-9]+)?([eE][-+]?[0-9]+)?/g,
    Id = /[ \t\n\r]+/g,
    Jd = /[^"]:/,
    Kd = /""/g,
    Ld = /true|false|null/g,
    Md = /00/,
    Nd = /[\{]([^0\}]|0[^:])/,
    Od = /(^|\[)[,:]|[,:](\]|\}|[,:]|$)/,
    Pd = /[^\[,:][\[\{]/,
    Qd = /^(\{|\}|\[|\]|,|:|0)+/,
    Rd = /\u2028/g,
    Sd = /\u2029/g,
    Td = function (a) {
      a = String(a);
      if (Cd.test(a) || Dd.test(a) || Ed.test(a) || Fd.test(a)) return !1;
      var b = a.replace(Gd, '""');
      b = b.replace(Hd, "0");
      b = b.replace(Id, "");
      if (Jd.test(b)) return !1;
      b = b.replace(Kd, "0");
      b = b.replace(Ld, "0");
      if (
        Md.test(b) ||
        Nd.test(b) ||
        Od.test(b) ||
        Pd.test(b) ||
        !b ||
        (b = b.replace(Qd, ""))
      )
        return !1;
      a = a.replace(Rd, "\\u2028").replace(Sd, "\\u2029");
      b = void 0;
      try {
        b = ud
          ? [X(a)]
          : eval(
              "(function (var_args) {\n  return Array.prototype.slice.call(arguments, 0);\n})(\n" +
                a +
                "\n)"
            );
      } catch (c) {
        return !1;
      }
      return b && 1 === b.length ? b[0] : !1;
    },
    Ud = function () {
      var a = ((q.document || {}).scripts || []).length;
      if ((void 0 === td || void 0 === ud || W !== a) && -1 !== W) {
        td = ud = !1;
        W = -1;
        try {
          try {
            ud =
              !!q.JSON &&
              '{"a":[3,true,"1970-01-01T00:00:00.000Z"]}' ===
                q.JSON.stringify.call(q.JSON, {
                  a: [3, !0, new Date(0)],
                  c: function () {},
                }) &&
              !0 === X("true") &&
              3 === X('[{"a":3}]')[0].a;
          } catch (b) {}
          td = ud && !X("[00]") && !X('"\u0007"') && !X('"\\0"') && !X('"\\v"');
        } finally {
          W = a;
        }
      }
    },
    Vd = function (a) {
      if (-1 === W) return !1;
      Ud();
      return (td ? X : Td)(a);
    },
    Wd = function (a) {
      if (-1 !== W) return Ud(), ud ? q.JSON.stringify.call(q.JSON, a) : Bd(a);
    },
    Xd =
      !Date.prototype.toISOString ||
      "function" !== typeof Date.prototype.toISOString ||
      "1970-01-01T00:00:00.000Z" !== new Date(0).toISOString(),
    Yd = function () {
      var a = Date.prototype.getUTCFullYear.call(this);
      return [
        0 > a
          ? "-" + String(1e6 - a).substr(1)
          : 9999 >= a
          ? String(1e4 + a).substr(1)
          : "+" + String(1e6 + a).substr(1),
        "-",
        String(101 + Date.prototype.getUTCMonth.call(this)).substr(1),
        "-",
        String(100 + Date.prototype.getUTCDate.call(this)).substr(1),
        "T",
        String(100 + Date.prototype.getUTCHours.call(this)).substr(1),
        ":",
        String(100 + Date.prototype.getUTCMinutes.call(this)).substr(1),
        ":",
        String(100 + Date.prototype.getUTCSeconds.call(this)).substr(1),
        ".",
        String(1e3 + Date.prototype.getUTCMilliseconds.call(this)).substr(1),
        "Z",
      ].join("");
    };
  Date.prototype.toISOString = Xd ? Yd : Date.prototype.toISOString;
  var Zd = function () {
    this.blockSize = -1;
  };
  var $d = function () {
    this.blockSize = -1;
    this.blockSize = 64;
    this.g = [];
    this.K = [];
    this.da = [];
    this.G = [];
    this.G[0] = 128;
    for (var a = 1; a < this.blockSize; ++a) this.G[a] = 0;
    this.H = this.o = 0;
    this.reset();
  };
  sa($d, Zd);
  $d.prototype.reset = function () {
    this.g[0] = 1732584193;
    this.g[1] = 4023233417;
    this.g[2] = 2562383102;
    this.g[3] = 271733878;
    this.g[4] = 3285377520;
    this.H = this.o = 0;
  };
  var ae = function (a, b, c) {
    c || (c = 0);
    var d = a.da;
    if ("string" === typeof b)
      for (var e = 0; 16 > e; e++)
        (d[e] =
          (b.charCodeAt(c) << 24) |
          (b.charCodeAt(c + 1) << 16) |
          (b.charCodeAt(c + 2) << 8) |
          b.charCodeAt(c + 3)),
          (c += 4);
    else
      for (e = 0; 16 > e; e++)
        (d[e] = (b[c] << 24) | (b[c + 1] << 16) | (b[c + 2] << 8) | b[c + 3]),
          (c += 4);
    for (e = 16; 80 > e; e++) {
      var f = d[e - 3] ^ d[e - 8] ^ d[e - 14] ^ d[e - 16];
      d[e] = ((f << 1) | (f >>> 31)) & 4294967295;
    }
    b = a.g[0];
    c = a.g[1];
    var g = a.g[2],
      h = a.g[3],
      k = a.g[4];
    for (e = 0; 80 > e; e++) {
      if (40 > e)
        if (20 > e) {
          f = h ^ (c & (g ^ h));
          var l = 1518500249;
        } else (f = c ^ g ^ h), (l = 1859775393);
      else
        60 > e
          ? ((f = (c & g) | (h & (c | g))), (l = 2400959708))
          : ((f = c ^ g ^ h), (l = 3395469782));
      f = (((b << 5) | (b >>> 27)) + f + k + l + d[e]) & 4294967295;
      k = h;
      h = g;
      g = ((c << 30) | (c >>> 2)) & 4294967295;
      c = b;
      b = f;
    }
    a.g[0] = (a.g[0] + b) & 4294967295;
    a.g[1] = (a.g[1] + c) & 4294967295;
    a.g[2] = (a.g[2] + g) & 4294967295;
    a.g[3] = (a.g[3] + h) & 4294967295;
    a.g[4] = (a.g[4] + k) & 4294967295;
  };
  $d.prototype.update = function (a, b) {
    if (null != a) {
      void 0 === b && (b = a.length);
      for (var c = b - this.blockSize, d = 0, e = this.K, f = this.o; d < b; ) {
        if (0 == f) for (; d <= c; ) ae(this, a, d), (d += this.blockSize);
        if ("string" === typeof a)
          for (; d < b; ) {
            if (((e[f] = a.charCodeAt(d)), ++f, ++d, f == this.blockSize)) {
              ae(this, e);
              f = 0;
              break;
            }
          }
        else
          for (; d < b; )
            if (((e[f] = a[d]), ++f, ++d, f == this.blockSize)) {
              ae(this, e);
              f = 0;
              break;
            }
      }
      this.o = f;
      this.H += b;
    }
  };
  $d.prototype.digest = function () {
    var a = [],
      b = 8 * this.H;
    56 > this.o
      ? this.update(this.G, 56 - this.o)
      : this.update(this.G, this.blockSize - (this.o - 56));
    for (var c = this.blockSize - 1; 56 <= c; c--)
      (this.K[c] = b & 255), (b /= 256);
    ae(this, this.K);
    for (c = b = 0; 5 > c; c++)
      for (var d = 24; 0 <= d; d -= 8) (a[b] = (this.g[c] >> d) & 255), ++b;
    return a;
  };
  var be = function () {
    this.P = new $d();
  };
  be.prototype.reset = function () {
    this.P.reset();
  };
  var ce = B.crypto,
    de = !1,
    ee = 0,
    fe = 0,
    ge = 1,
    he = 0,
    ie = "",
    je = function (a) {
      a = a || B.event;
      var b = (a.screenX + a.clientX) << 16;
      b += a.screenY + a.clientY;
      b *= new Date().getTime() % 1e6;
      ge = (ge * b) % he;
      0 < ee && ++fe == ee && bc("mousemove", je, "remove", "de");
    },
    ke = function (a) {
      var b = new be();
      a = unescape(encodeURIComponent(a));
      for (var c = [], d = 0, e = a.length; d < e; ++d) c.push(a.charCodeAt(d));
      b.P.update(c);
      b = b.P.digest();
      a = "";
      for (c = 0; c < b.length; c++)
        a +=
          "0123456789ABCDEF".charAt(Math.floor(b[c] / 16)) +
          "0123456789ABCDEF".charAt(b[c] % 16);
      return a;
    };
  de = !!ce && "function" == typeof ce.getRandomValues;
  de ||
    ((he = 1e6 * (screen.width * screen.width + screen.height)),
    (ie = ke(
      C.cookie +
        "|" +
        C.location +
        "|" +
        new Date().getTime() +
        "|" +
        Math.random()
    )),
    (ee = V("random/maxObserveMousemove") || 0),
    0 != ee && bc("mousemove", je, "add", "at"));
  var le = function () {
      var a = L.onl;
      if (!a) {
        a = E();
        L.onl = a;
        var b = E();
        a.e = function (c) {
          var d = b[c];
          d && (delete b[c], d());
        };
        a.a = function (c, d) {
          b[c] = d;
        };
        a.r = function (c) {
          delete b[c];
        };
      }
      return a;
    },
    me = function (a, b) {
      b = b.onload;
      return "function" === typeof b ? (le().a(a, b), b) : null;
    },
    ne = function (a) {
      H(/^\w+$/.test(a), "Unsupported id - " + a);
      return 'onload="window.___jsl.onl.e(&#34;' + a + '&#34;)"';
    },
    oe = function (a) {
      le().r(a);
    };
  var pe = {
      allowtransparency: "true",
      frameborder: "0",
      hspace: "0",
      marginheight: "0",
      marginwidth: "0",
      scrolling: "no",
      style: "",
      tabindex: "0",
      vspace: "0",
      width: "100%",
    },
    qe = { allowtransparency: !0, onload: !0 },
    re = 0,
    se = function (a) {
      H(!a || $b.test(a), "Illegal url for new iframe - " + a);
    },
    te = function (a, b, c, d, e) {
      se(c.src);
      var f,
        g = me(d, c),
        h = g ? ne(d) : "";
      try {
        document.all &&
          (f = a.createElement(
            '<iframe frameborder="' +
              Mb(String(c.frameborder)) +
              '" scrolling="' +
              Mb(String(c.scrolling)) +
              '" ' +
              h +
              ' name="' +
              Mb(String(c.name)) +
              '"/>'
          ));
      } catch (l) {
      } finally {
        f ||
          ((f = (a ? new Bb(Ab(a)) : wa || (wa = new Bb())).fa("IFRAME")),
          g &&
            ((f.onload = function () {
              f.onload = null;
              g.call(this);
            }),
            oe(d)));
      }
      f.setAttribute("ng-non-bindable", "");
      for (var k in c)
        (a = c[k]),
          "style" === k && "object" === typeof a
            ? G(a, f.style)
            : qe[k] || f.setAttribute(k, String(a));
      (k = (e && e.beforeNode) || null) || (e && e.dontclear) || gc(b);
      b.insertBefore(f, k);
      f = k ? k.previousSibling : b.lastChild;
      c.allowtransparency && (f.allowTransparency = !0);
      return f;
    };
  var ue = /^:[\w]+$/,
    ve = /:([a-zA-Z_]+):/g,
    we = function () {
      var a = rd() || "0",
        b = sd();
      var c = rd(void 0) || a;
      var d = sd(void 0),
        e = "";
      c && (e += "u/" + encodeURIComponent(String(c)) + "/");
      d && (e += "b/" + encodeURIComponent(String(d)) + "/");
      c = e || null;
      (e = (d = !1 === V("isLoggedIn")) ? "_/im/" : "") && (c = "");
      var f = V("iframes/:socialhost:"),
        g = V("iframes/:im_socialhost:");
      return (ac = {
        socialhost: f,
        ctx_socialhost: d ? g : f,
        session_index: a,
        session_delegate: b,
        session_prefix: c,
        im_prefix: e,
      });
    },
    xe = function (a, b) {
      return we()[b] || "";
    },
    ye = function (a) {
      return function (b, c) {
        return a ? we()[c] || a[c] || "" : we()[c] || "";
      };
    };
  var ze = function (a) {
      var b;
      a.match(/^https?%3A/i) && (b = decodeURIComponent(a));
      return Zb(document, b ? b : a);
    },
    Ae = function (a) {
      a = a || "canonical";
      for (
        var b = document.getElementsByTagName("link"), c = 0, d = b.length;
        c < d;
        c++
      ) {
        var e = b[c],
          f = e.getAttribute("rel");
        if (
          f &&
          f.toLowerCase() == a &&
          (e = e.getAttribute("href")) &&
          (e = ze(e)) &&
          null != e.match(/^https?:\/\/[\w\-_\.]+/i)
        )
          return e;
      }
      return window.location.href;
    };
  var Be = { se: "0" },
    Ce = { post: !0 },
    De = {
      style:
        "position:absolute;top:-10000px;width:450px;margin:0px;border-style:none",
    },
    Ee =
      "onPlusOne _ready _close _open _resizeMe _renderstart oncircled drefresh erefresh".split(
        " "
      ),
    Fe = D(L, "WI", E()),
    Ge = function (a, b, c) {
      var d;
      var e = {};
      var f = (d = a);
      "plus" == a &&
        b.action &&
        ((d = a + "_" + b.action), (f = a + "/" + b.action));
      (d = Q("iframes/" + d + "/url")) ||
        (d =
          ":im_socialhost:/:session_prefix::im_prefix:_/widget/render/" +
          f +
          "?usegapi=1");
      for (var g in Be) e[g] = g + "/" + (b[g] || Be[g]) + "/";
      e = Zb(C, d.replace(ve, ye(e)));
      g = "iframes/" + a + "/params/";
      f = {};
      G(b, f);
      (d = Q("lang") || Q("gwidget/lang")) && (f.hl = d);
      Ce[a] ||
        (f.origin =
          window.location.origin ||
          window.location.protocol + "//" + window.location.host);
      f.exp = Q(g + "exp");
      if ((g = Q(g + "location")))
        for (d = 0; d < g.length; d++) {
          var h = g[d];
          f[h] = B.location[h];
        }
      switch (a) {
        case "plus":
        case "follow":
          g = f.href;
          d = b.action ? void 0 : "publisher";
          g = (g = "string" == typeof g ? g : void 0) ? ze(g) : Ae(d);
          f.url = g;
          delete f.href;
          break;
        case "plusone":
          g = (g = b.href) ? ze(g) : Ae();
          f.url = g;
          g = b.db;
          d = Q();
          null == g &&
            d &&
            ((g = d.db), null == g && (g = d.gwidget && d.gwidget.db));
          f.db = g || void 0;
          g = b.ecp;
          d = Q();
          null == g &&
            d &&
            ((g = d.ecp), null == g && (g = d.gwidget && d.gwidget.ecp));
          f.ecp = g || void 0;
          delete f.href;
          break;
        case "signin":
          f.url = Ae();
      }
      L.ILI && (f.iloader = "1");
      delete f["data-onload"];
      delete f.rd;
      for (var k in Be) f[k] && delete f[k];
      f.gsrc = Q("iframes/:source:");
      k = Q("inline/css");
      "undefined" !== typeof k && 0 < c && k >= c && (f.ic = "1");
      k = /^#|^fr-/;
      c = {};
      for (var l in f)
        F(f, l) && k.test(l) && ((c[l.replace(k, "")] = f[l]), delete f[l]);
      l = "q" == Q("iframes/" + a + "/params/si") ? f : c;
      k = fd();
      for (var m in k) !F(k, m) || F(f, m) || F(c, m) || (l[m] = k[m]);
      m = [].concat(Ee);
      (l = Q("iframes/" + a + "/methods")) &&
        "object" === typeof l &&
        Eb.test(l.push) &&
        (m = m.concat(l));
      for (var t in b)
        F(b, t) &&
          /^on/.test(t) &&
          ("plus" != a || "onconnect" != t) &&
          (m.push(t), delete f[t]);
      delete f.callback;
      c._methods = m.join(",");
      return Xb(e, f, c);
    },
    He = ["style", "data-gapiscan"],
    Je = function (a) {
      for (
        var b = E(),
          c = 0 != a.nodeName.toLowerCase().indexOf("g:"),
          d = 0,
          e = a.attributes.length;
        d < e;
        d++
      ) {
        var f = a.attributes[d],
          g = f.name,
          h = f.value;
        0 <= Fb.call(He, g) ||
          (c && 0 != g.indexOf("data-")) ||
          "null" === h ||
          ("specified" in f && !f.specified) ||
          (c && (g = g.substr(5)), (b[g.toLowerCase()] = h));
      }
      a = a.style;
      (c = Ie(a && a.height)) && (b.height = String(c));
      (a = Ie(a && a.width)) && (b.width = String(a));
      return b;
    },
    Ie = function (a) {
      var b = void 0;
      "number" === typeof a
        ? (b = a)
        : "string" === typeof a && (b = parseInt(a, 10));
      return b;
    },
    Le = function () {
      var a = L.drw;
      nd(function (b) {
        if (a !== b.id && 4 != b.state && "share" != b.type) {
          var c = b.id,
            d = b.type,
            e = b.url;
          b = b.userParams;
          var f = C.getElementById(c);
          if (f) {
            var g = Ge(d, b, 0);
            g
              ? ((f = f.parentNode),
                e.replace(/#.*/, "").replace(/(\?|&)ic=1/, "") !==
                  g.replace(/#.*/, "").replace(/(\?|&)ic=1/, "") &&
                  ((b.dontclear = !0),
                  (b.rd = !0),
                  (b.ri = !0),
                  (b.type = d),
                  Ke(f, b),
                  (d = U[f.lastChild.id]) && (d.oid = c),
                  od(c, 4)))
              : delete U[c];
          } else delete U[c];
        }
      });
    };
  var Me,
    Ne,
    Oe,
    Pe,
    Qe,
    Re = /(?:^|\s)g-((\S)*)(?:$|\s)/,
    Se = {
      plusone: !0,
      autocomplete: !0,
      profile: !0,
      signin: !0,
      signin2: !0,
    };
  Me = D(L, "SW", E());
  Ne = D(L, "SA", E());
  Oe = D(L, "SM", E());
  Pe = D(L, "FW", []);
  Qe = null;
  var Ue = function (a, b) {
      Te(void 0, !1, a, b);
    },
    Te = function (a, b, c, d) {
      N("ps0", !0);
      c = ("string" === typeof c ? document.getElementById(c) : c) || C;
      var e = C.documentMode;
      if (c.querySelectorAll && (!e || 8 < e)) {
        e = d ? [d] : Nb(Me).concat(Nb(Ne)).concat(Nb(Oe));
        for (var f = [], g = 0; g < e.length; g++) {
          var h = e[g];
          f.push(".g-" + h, "g\\:" + h);
        }
        e = c.querySelectorAll(f.join(","));
      } else e = c.getElementsByTagName("*");
      c = E();
      for (f = 0; f < e.length; f++) {
        g = e[f];
        var k = g;
        h = d;
        var l = k.nodeName.toLowerCase(),
          m = void 0;
        if (k.getAttribute("data-gapiscan")) h = null;
        else {
          var t = l.indexOf("g:");
          0 == t
            ? (m = l.substr(2))
            : (t =
                (t = String(k.className || k.getAttribute("class"))) &&
                Re.exec(t)) && (m = t[1]);
          h = !m || !(Me[m] || Ne[m] || Oe[m]) || (h && m !== h) ? null : m;
        }
        h &&
          (Se[h] ||
            0 == g.nodeName.toLowerCase().indexOf("g:") ||
            0 != Nb(Je(g)).length) &&
          (g.setAttribute("data-gapiscan", !0), D(c, h, []).push(g));
      }
      if (b)
        for (var u in c)
          for (b = c[u], d = 0; d < b.length; d++)
            b[d].setAttribute("data-onload", !0);
      for (var p in c) Pe.push(p);
      N("ps1", !0);
      if ((u = Pe.join(":")) || a)
        try {
          I.load(u, a);
        } catch (y) {
          id(y);
          return;
        }
      if (Ve(Qe || {}))
        for (var K in c) {
          a = c[K];
          p = 0;
          for (b = a.length; p < b; p++) a[p].removeAttribute("data-gapiscan");
          We(K);
        }
      else {
        d = [];
        for (K in c)
          for (a = c[K], p = 0, b = a.length; p < b; p++)
            (e = a[p]), Xe(K, e, Je(e), d, b);
        Ye(u, d);
      }
    },
    Ze = function (a) {
      var b = D(I, a, {});
      b.go ||
        ((b.go = function (c) {
          return Ue(c, a);
        }),
        (b.render = function (c, d) {
          d = d || {};
          d.type = a;
          return Ke(c, d);
        }));
    },
    $e = function (a) {
      Me[a] = !0;
    },
    af = function (a) {
      Ne[a] = !0;
    },
    bf = function (a) {
      Oe[a] = !0;
    };
  var We = function (a, b) {
      var c = kc(a);
      b && c
        ? (c(b), (c = b.iframeNode) && c.setAttribute("data-gapiattached", !0))
        : I.load(a, function () {
            var d = kc(a),
              e = b && b.iframeNode,
              f = b && b.userParams;
            e && d
              ? (d(b), e.setAttribute("data-gapiattached", !0))
              : ((d = I[a].go),
                "signin2" == a ? d(e, f) : d(e && e.parentNode, f));
          });
    },
    Ve = function () {
      return !1;
    },
    Ye = function () {},
    Xe = function (a, b, c, d, e, f, g) {
      switch (cf(b, a, f)) {
        case 0:
          a = Oe[a] ? a + "_annotation" : a;
          d = {};
          d.iframeNode = b;
          d.userParams = c;
          We(a, d);
          break;
        case 1:
          if (b.parentNode) {
            for (var h in c) {
              if ((f = F(c, h)))
                (f = c[h]),
                  (f =
                    !!f &&
                    "object" === typeof f &&
                    (!f.toString ||
                      f.toString === Object.prototype.toString ||
                      f.toString === Array.prototype.toString));
              if (f)
                try {
                  c[h] = Wd(c[h]);
                } catch (M) {
                  delete c[h];
                }
            }
            f = !0;
            c.dontclear && (f = !1);
            delete c.dontclear;
            md();
            h = Ge(a, c, e);
            e = g || {};
            e.allowPost = 1;
            e.attributes = De;
            e.dontclear = !f;
            g = {};
            g.userParams = c;
            g.url = h;
            g.type = a;
            if (c.rd) var k = b;
            else
              (k = document.createElement("div")),
                b.setAttribute("data-gapistub", !0),
                (k.style.cssText =
                  "position:absolute;width:450px;left:-10000px;"),
                b.parentNode.insertBefore(k, b);
            g.siteElement = k;
            k.id ||
              ((b = k),
              D(Fe, a, 0),
              (f = "___" + a + "_" + Fe[a]++),
              (b.id = f));
            b = E();
            b[">type"] = a;
            G(c, b);
            f = h;
            c = k;
            h = e || {};
            b = h.attributes || {};
            H(
              !(h.allowPost || h.forcePost) || !b.onload,
              "onload is not supported by post iframe (allowPost or forcePost)"
            );
            e = b = f;
            ue.test(b) &&
              ((e = V("iframes/" + e.substring(1) + "/url")),
              H(!!e, "Unknown iframe url config for - " + b));
            f = Zb(C, e.replace(ve, xe));
            b = c.ownerDocument || C;
            e = h;
            var l = 0;
            do k = e.id || ["I", re++, "_", new Date().getTime()].join("");
            while (b.getElementById(k) && 5 > ++l);
            H(5 > l, "Error creating iframe id");
            e = k;
            k = h;
            l = {};
            var m = {};
            b.documentMode &&
              9 > b.documentMode &&
              (l.hostiemode = b.documentMode);
            G(k.queryParams || {}, l);
            G(k.fragmentParams || {}, m);
            var t = k.pfname;
            var u = E();
            V("iframes/dropLegacyIdParam") || (u.id = e);
            u._gfid = e;
            u.parent = b.location.protocol + "//" + b.location.host;
            var p = J(b.location.href, "parent");
            t = t || "";
            !t &&
              p &&
              ((p =
                J(b.location.href, "_gfid", "") ||
                J(b.location.href, "id", "")),
              (t = J(b.location.href, "pfname", "")),
              (t = p ? t + "/" + p : ""));
            t ||
              ((p = Vd(J(b.location.href, "jcp", ""))) &&
                "object" == typeof p &&
                (t = (t = p.id) ? p.pfname + "/" + t : ""));
            u.pfname = t;
            k.connectWithJsonParam && ((p = {}), (p.jcp = Wd(u)), (u = p));
            p = J(f, "rpctoken") || l.rpctoken || m.rpctoken;
            if (!p) {
              if (!(p = k.rpctoken)) {
                p = String;
                t = Math;
                var K = t.round;
                if (de) {
                  var y = new B.Uint32Array(1);
                  ce.getRandomValues(y);
                  y = Number("0." + y[0]);
                } else
                  (y = ge),
                    (y += parseInt(ie.substr(0, 20), 16)),
                    (ie = ke(ie)),
                    (y /= he + Math.pow(16, 20));
                p = p(K.call(t, 1e8 * y));
              }
              u.rpctoken = p;
            }
            k.rpctoken = p;
            G(u, k.connectWithQueryParams ? l : m);
            p = b.location.href;
            u = E();
            (t = J(p, "_bsh", L.bsh)) && (u._bsh = t);
            (p = ic(p)) && (u.jsh = p);
            k.hintInFragment ? G(u, m) : G(u, l);
            l = Xb(f, l, m, k.paramsSerializer);
            f = h;
            m = E();
            G(pe, m);
            G(f.attributes, m);
            m.name = m.id = e;
            m.src = l;
            h.eurl = l;
            h = (k = h) || {};
            f = !!h.allowPost;
            if (h.forcePost || (f && 2e3 < l.length)) {
              f = Ub(l);
              m.src = "";
              k.dropDataPostorigin || (m["data-postorigin"] = l);
              h = te(b, c, m, e);
              if (-1 != navigator.userAgent.indexOf("WebKit")) {
                var v = h.contentWindow.document;
                v.open();
                l = v.createElement("div");
                m = {};
                u = e + "_inner";
                m.name = u;
                m.src = "";
                m.style = "display:none";
                te(b, l, m, u, k);
              }
              l = (k = f.query[0]) ? k.split("&") : [];
              k = [];
              for (m = 0; m < l.length; m++)
                (u = l[m].split("=", 2)),
                  k.push([decodeURIComponent(u[0]), decodeURIComponent(u[1])]);
              f.query = [];
              l = Vb(f);
              H($b.test(l), "Invalid URL: " + l);
              f = b.createElement("form");
              f.method = "POST";
              f.target = e;
              f.style.display = "none";
              e = l instanceof x ? l : Wa(l);
              bb(f, "HTMLFormElement").action = Ua(e);
              for (e = 0; e < k.length; e++)
                (l = b.createElement("input")),
                  (l.type = "hidden"),
                  (l.name = k[e][0]),
                  (l.value = k[e][1]),
                  f.appendChild(l);
              c.appendChild(f);
              f.submit();
              f.parentNode.removeChild(f);
              v && v.close();
              v = h;
            } else v = te(b, c, m, e, k);
            g.iframeNode = v;
            g.id = v.getAttribute("id");
            v = g.id;
            c = E();
            c.id = v;
            c.userParams = g.userParams;
            c.url = g.url;
            c.type = g.type;
            c.state = 1;
            U[v] = c;
            v = g;
          } else v = null;
          v && ((g = v.id) && d.push(g), We(a, v));
      }
    },
    cf = function (a, b, c) {
      if (a && 1 === a.nodeType && b) {
        if (c) return 1;
        if (Oe[b]) {
          if (hc[a.nodeName.toLowerCase()])
            return (a = a.innerHTML) && a.replace(/^[\s\xa0]+|[\s\xa0]+$/g, "")
              ? 0
              : 1;
        } else {
          if (Ne[b]) return 0;
          if (Me[b]) return 1;
        }
      }
      return null;
    },
    Ke = function (a, b) {
      var c = b.type;
      delete b.type;
      var d =
        ("string" === typeof a ? document.getElementById(a) : a) || void 0;
      if (d) {
        a = {};
        for (var e in b) F(b, e) && (a[e.toLowerCase()] = b[e]);
        a.rd = 1;
        (b = !!a.ri) && delete a.ri;
        e = [];
        Xe(c, d, a, e, 0, b, void 0);
        Ye(c, e);
      } else
        id(
          "string" === "gapi." + c + ".render: missing element " + typeof a
            ? a
            : ""
        );
    };
  D(I, "platform", {}).go = Ue;
  Ve = function (a) {
    for (var b = ["_c", "jsl", "h"], c = 0; c < b.length && a; c++) a = a[b[c]];
    b = ic(Cb.href);
    return !a || (0 != a.indexOf("n;") && 0 != b.indexOf("n;") && a !== b);
  };
  Ye = function (a, b) {
    df(a, b);
  };
  var dc = function (a) {
      Te(a, !0);
    },
    ef = function (a, b) {
      b = b || [];
      for (var c = 0; c < b.length; ++c) a(b[c]);
      for (a = 0; a < b.length; a++) Ze(b[a]);
    };
  uc.push([
    "platform",
    function (a, b, c) {
      Qe = c;
      b && Pe.push(b);
      ef($e, a);
      ef(af, c._c.annotation);
      ef(bf, c._c.bimodal);
      bd();
      $c();
      if ("explicit" != Q("parsetags")) {
        jc(a);
        gd(fd()) && !Q("disableRealtimeCallback") && md();
        if (c && (a = c.callback)) {
          var d = Ob(a);
          delete c.callback;
        }
        fc(function () {
          dc(d);
        });
      }
    },
  ]);
  I._pl = !0;
  var ff = function (a) {
    a = (a = U[a]) ? a.oid : void 0;
    if (a) {
      var b = C.getElementById(a);
      b && b.parentNode.removeChild(b);
      delete U[a];
      ff(a);
    }
  };
  var gf = /^\{h:'/,
    hf = /^!_/,
    jf = "",
    df = function (a, b) {
      function c() {
        bc("message", d, "remove", "de");
      }
      function d(f) {
        var g = f.data,
          h = f.origin;
        if (kf(g, b)) {
          var k = e;
          e = !1;
          k && N("rqe");
          lf(a, function () {
            k && N("rqd");
            c();
            for (var l = D(L, "RPMQ", []), m = 0; m < l.length; m++)
              l[m]({ data: g, origin: h });
          });
        }
      }
      if (0 !== b.length) {
        jf = J(Cb.href, "pfname", "");
        var e = !0;
        bc("message", d, "add", "at");
        Uc(a, c);
      }
    },
    kf = function (a, b) {
      a = String(a);
      if (gf.test(a)) return !0;
      var c = !1;
      hf.test(a) && ((c = !0), (a = a.substr(2)));
      if (!/^\{/.test(a)) return !1;
      var d = Vd(a);
      if (!d) return !1;
      a = d.f;
      if (d.s && a && -1 != Fb.call(b, a)) {
        if ("_renderstart" === d.s || d.s === jf + "/" + a + "::_renderstart")
          if (
            ((d = d.a && d.a[c ? 0 : 1]),
            (b = C.getElementById(a)),
            od(a, 2),
            d && b && d.width && d.height)
          ) {
            a: {
              c = b.parentNode;
              a = d || {};
              if (ld()) {
                var e = b.id;
                if (e) {
                  d = (d = U[e]) ? d.state : void 0;
                  if (1 === d || 4 === d) break a;
                  ff(e);
                }
              }
              (d = c.nextSibling) &&
                d.getAttribute &&
                d.getAttribute("data-gapistub") &&
                (c.parentNode.removeChild(d), (c.style.cssText = ""));
              d = a.width;
              var f = a.height,
                g = c.style;
              g.textIndent = "0";
              g.margin = "0";
              g.padding = "0";
              g.background = "transparent";
              g.borderStyle = "none";
              g.cssFloat = "none";
              g.styleFloat = "none";
              g.lineHeight = "normal";
              g.fontSize = "1px";
              g.verticalAlign = "baseline";
              c = c.style;
              c.display = "inline-block";
              g = b.style;
              g.position = "static";
              g.left = "0";
              g.top = "0";
              g.visibility = "visible";
              d && (c.width = g.width = d + "px");
              f && (c.height = g.height = f + "px");
              a.verticalAlign && (c.verticalAlign = a.verticalAlign);
              e && od(e, 3);
            }
            b["data-csi-wdt"] = new Date().getTime();
          }
        return !0;
      }
      return !1;
    },
    lf = function (a, b) {
      Uc(a, b);
    };
  var mf = function (a, b) {
    this.M = a;
    a = b || {};
    this.ga = Number(a.maxAge) || 0;
    this.V = a.domain;
    this.X = a.path;
    this.ha = !!a.secure;
  };
  mf.prototype.read = function () {
    for (
      var a = this.M + "=", b = document.cookie.split(/;\s*/), c = 0;
      c < b.length;
      ++c
    ) {
      var d = b[c];
      if (0 == d.indexOf(a)) return d.substr(a.length);
    }
  };
  mf.prototype.write = function (a, b) {
    if (!nf.test(this.M)) throw "Invalid cookie name";
    if (!of.test(a)) throw "Invalid cookie value";
    a = this.M + "=" + a;
    this.V && (a += ";domain=" + this.V);
    this.X && (a += ";path=" + this.X);
    b = "number" === typeof b ? b : this.ga;
    if (0 <= b) {
      var c = new Date();
      c.setSeconds(c.getSeconds() + b);
      a += ";expires=" + c.toUTCString();
    }
    this.ha && (a += ";secure");
    document.cookie = a;
    return !0;
  };
  mf.prototype.clear = function () {
    this.write("", 0);
  };
  var of = /^[-+/_=.:|%&a-zA-Z0-9@]*$/,
    nf = /^[A-Z_][A-Z0-9_]{0,63}$/;
  mf.iterate = function (a) {
    for (var b = document.cookie.split(/;\s*/), c = 0; c < b.length; ++c) {
      var d = b[c].split("="),
        e = d.shift();
      a(e, d.join("="));
    }
  };
  var pf = function (a) {
    this.F = a;
  };
  pf.prototype.read = function () {
    if (Z.hasOwnProperty(this.F)) return Z[this.F];
  };
  pf.prototype.write = function (a) {
    Z[this.F] = a;
    return !0;
  };
  pf.prototype.clear = function () {
    delete Z[this.F];
  };
  var Z = {};
  pf.iterate = function (a) {
    for (var b in Z) Z.hasOwnProperty(b) && a(b, Z[b]);
  };
  var qf = "https:" === window.location.protocol,
    rf = qf || "http:" === window.location.protocol ? mf : pf,
    sf = function (a) {
      var b = a.substr(1),
        c = "",
        d = window.location.hostname;
      if ("" !== b) {
        c = parseInt(b, 10);
        if (isNaN(c)) return null;
        b = d.split(".");
        if (b.length < c - 1) return null;
        b.length == c - 1 && (d = "." + d);
      } else d = "";
      return { j: "S" == a.charAt(0), domain: d, l: c };
    },
    tf = function () {
      var a,
        b = null;
      rf.iterate(function (c, d) {
        0 === c.indexOf("G_AUTHUSER_") &&
          ((c = sf(c.substring(11))),
          !a || (c.j && !a.j) || (c.j == a.j && c.l > a.l)) &&
          ((a = c), (b = d));
      });
      return { ea: a, J: b };
    };
  function uf(a) {
    if (0 !== a.indexOf("GCSC")) return null;
    var b = { W: !1 };
    a = a.substr(4);
    if (!a) return b;
    var c = a.charAt(0);
    a = a.substr(1);
    var d = a.lastIndexOf("_");
    if (-1 == d) return b;
    var e = sf(a.substr(d + 1));
    if (null == e) return b;
    a = a.substring(0, d);
    if ("_" !== a.charAt(0)) return b;
    d = "E" === c && e.j;
    return (!d && ("U" !== c || e.j)) || (d && !qf)
      ? b
      : { W: !0, j: d, ka: a.substr(1), domain: e.domain, l: e.l };
  }
  var vf = function (a) {
      if (!a) return [];
      a = a.split("=");
      return a[1] ? a[1].split("|") : [];
    },
    wf = function (a) {
      a = a.split(":");
      return {
        clientId: a[0].split("=")[1],
        ja: vf(a[1]),
        ma: vf(a[2]),
        la: vf(a[3]),
      };
    },
    xf = function () {
      var a = tf(),
        b = a.ea;
      a = a.J;
      if (null !== a) {
        var c;
        rf.iterate(function (f, g) {
          (f = uf(f)) && f.W && f.j == b.j && f.l == b.l && (c = g);
        });
        if (c) {
          var d = wf(c),
            e = d && d.ja[Number(a)];
          d = d && d.clientId;
          if (e) return { J: a, ia: e, clientId: d };
        }
      }
      return null;
    };
  var zf = function () {
    this.U = yf;
  };
  n = zf.prototype;
  n.$ = function () {
    this.L || ((this.v = 0), (this.L = !0), this.Y());
  };
  n.Y = function () {
    this.L &&
      (this.U()
        ? (this.v = this.S)
        : (this.v = Math.min(2 * (this.v || this.S), 120)),
      window.setTimeout(ra(this.Y, this), 1e3 * this.v));
  };
  n.v = 0;
  n.S = 2;
  n.U = null;
  n.L = !1;
  for (var Af = 0; 64 > Af; ++Af);
  var Bf = null;
  ld = function () {
    return (L.oa = !0);
  };
  md = function () {
    L.oa = !0;
    var a = xf();
    (a = a && a.J) && ad("googleapis.config/sessionIndex", a);
    Bf || (Bf = D(L, "ss", new zf()));
    a = Bf;
    a.$ && a.$();
  };
  var yf = function () {
    var a = xf(),
      b = (a && a.ia) || null,
      c = a && a.clientId;
    Uc("auth", {
      callback: function () {
        var d = B.gapi.auth,
          e = { client_id: c, session_state: b };
        d.checkSessionState(e, function (f) {
          var g = e.session_state,
            h = !!Q("isLoggedIn");
          f = Q("debug/forceIm") ? !1 : (g && f) || (!g && !f);
          if ((h = h !== f))
            ad("isLoggedIn", f),
              md(),
              Le(),
              f || ((f = d.signOut) ? f() : (f = d.setToken) && f(null));
          f = fd();
          var k = Q("savedUserState");
          g = d._guss(f.cookiepolicy);
          k = k != g && "undefined" != typeof k;
          ad("savedUserState", g);
          (h || k) && gd(f) && !Q("disableRealtimeCallback") && d._pimf(f, !0);
        });
      },
    });
    return !0;
  };
  N("bs0", !0, window.gapi._bs);
  N("bs1", !0);
  delete window.gapi._bs;
}.call(this));
gapi.load("client", {
  callback: window["gapi_onload"],
  _c: {
    jsl: {
      ci: {
        deviceType: "desktop",
        "oauth-flow": {
          authUrl: "https://accounts.google.com/o/oauth2/auth",
          proxyUrl: "https://accounts.google.com/o/oauth2/postmessageRelay",
          disableOpt: true,
          idpIframeUrl: "https://accounts.google.com/o/oauth2/iframe",
          usegapi: false,
        },
        debug: {
          reportExceptionRate: 0.05,
          forceIm: false,
          rethrowException: false,
          host: "https://apis.google.com",
        },
        enableMultilogin: true,
        "googleapis.config": { auth: { useFirstPartyAuthV2: true } },
        inline: { css: 1 },
        disableRealtimeCallback: false,
        drive_share: { skipInitCommand: true },
        csi: { rate: 0.01 },
        client: { cors: false },
        signInDeprecation: { rate: 0.0 },
        include_granted_scopes: true,
        llang: "en",
        iframes: {
          youtube: {
            params: { location: ["search", "hash"] },
            url: ":socialhost:/:session_prefix:_/widget/render/youtube?usegapi\u003d1",
            methods: ["scroll", "openwindow"],
          },
          ytsubscribe: {
            url: "https://www.youtube.com/subscribe_embed?usegapi\u003d1",
          },
          plus_circle: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix::se:_/widget/plus/circle?usegapi\u003d1",
          },
          plus_share: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix::se:_/+1/sharebutton?plusShare\u003dtrue\u0026usegapi\u003d1",
          },
          rbr_s: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix::se:_/widget/render/recobarsimplescroller",
          },
          ":source:": "3p",
          playemm: {
            url: "https://play.google.com/work/embedded/search?usegapi\u003d1\u0026usegapi\u003d1",
          },
          savetoandroidpay: { url: "https://pay.google.com/gp/v/widget/save" },
          blogger: {
            params: { location: ["search", "hash"] },
            url: ":socialhost:/:session_prefix:_/widget/render/blogger?usegapi\u003d1",
            methods: ["scroll", "openwindow"],
          },
          evwidget: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix:_/events/widget?usegapi\u003d1",
          },
          partnersbadge: {
            url: "https://www.gstatic.com/partners/badge/templates/badge.html?usegapi\u003d1",
          },
          dataconnector: {
            url: "https://dataconnector.corp.google.com/:session_prefix:ui/widgetview?usegapi\u003d1",
          },
          surveyoptin: {
            url: "https://www.google.com/shopping/customerreviews/optin?usegapi\u003d1",
          },
          ":socialhost:": "https://apis.google.com",
          shortlists: { url: "" },
          hangout: {
            url: "https://talkgadget.google.com/:session_prefix:talkgadget/_/widget",
          },
          plus_followers: {
            params: { url: "" },
            url: ":socialhost:/_/im/_/widget/render/plus/followers?usegapi\u003d1",
          },
          post: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix::im_prefix:_/widget/render/post?usegapi\u003d1",
          },
          ":gplus_url:": "https://plus.google.com",
          signin: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix:_/widget/render/signin?usegapi\u003d1",
            methods: ["onauth"],
          },
          rbr_i: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix::se:_/widget/render/recobarinvitation",
          },
          share: {
            url: ":socialhost:/:session_prefix::im_prefix:_/widget/render/share?usegapi\u003d1",
          },
          plusone: {
            params: { count: "", size: "", url: "" },
            url: ":socialhost:/:session_prefix::se:_/+1/fastbutton?usegapi\u003d1",
          },
          comments: {
            params: { location: ["search", "hash"] },
            url: ":socialhost:/:session_prefix:_/widget/render/comments?usegapi\u003d1",
            methods: ["scroll", "openwindow"],
          },
          ":im_socialhost:": "https://plus.googleapis.com",
          backdrop: {
            url: "https://clients3.google.com/cast/chromecast/home/widget/backdrop?usegapi\u003d1",
          },
          visibility: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix:_/widget/render/visibility?usegapi\u003d1",
          },
          autocomplete: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix:_/widget/render/autocomplete",
          },
          additnow: {
            url: "https://apis.google.com/marketplace/button?usegapi\u003d1",
            methods: ["launchurl"],
          },
          ":signuphost:": "https://plus.google.com",
          ratingbadge: {
            url: "https://www.google.com/shopping/customerreviews/badge?usegapi\u003d1",
          },
          appcirclepicker: {
            url: ":socialhost:/:session_prefix:_/widget/render/appcirclepicker",
          },
          follow: {
            url: ":socialhost:/:session_prefix:_/widget/render/follow?usegapi\u003d1",
          },
          community: {
            url: ":ctx_socialhost:/:session_prefix::im_prefix:_/widget/render/community?usegapi\u003d1",
          },
          sharetoclassroom: {
            url: "https://classroom.google.com/sharewidget?usegapi\u003d1",
          },
          ytshare: {
            params: { url: "" },
            url: ":socialhost:/:session_prefix:_/widget/render/ytshare?usegapi\u003d1",
          },
          plus: {
            url: ":socialhost:/:session_prefix:_/widget/render/badge?usegapi\u003d1",
          },
          family_creation: {
            params: { url: "" },
            url: "https://families.google.com/webcreation?usegapi\u003d1\u0026usegapi\u003d1",
          },
          commentcount: {
            url: ":socialhost:/:session_prefix:_/widget/render/commentcount?usegapi\u003d1",
          },
          configurator: {
            url: ":socialhost:/:session_prefix:_/plusbuttonconfigurator?usegapi\u003d1",
          },
          zoomableimage: { url: "https://ssl.gstatic.com/microscope/embed/" },
          appfinder: {
            url: "https://workspace.google.com/:session_prefix:marketplace/appfinder?usegapi\u003d1",
          },
          savetowallet: { url: "https://pay.google.com/gp/v/widget/save" },
          person: {
            url: ":socialhost:/:session_prefix:_/widget/render/person?usegapi\u003d1",
          },
          savetodrive: {
            url: "https://drive.google.com/savetodrivebutton?usegapi\u003d1",
            methods: ["save"],
          },
          page: {
            url: ":socialhost:/:session_prefix:_/widget/render/page?usegapi\u003d1",
          },
          card: { url: ":socialhost:/:session_prefix:_/hovercard/card" },
        },
      },
      h: "m;/_/scs/apps-static/_/js/k\u003doz.gapi.en.-B-Yl1giugs.O/am\u003dAQ/d\u003d1/rs\u003dAGLTcCONWTFvihGrQAtWDoeeH_UUFfCMQg/m\u003d__features__",
      u: "https://apis.google.com/js/client:platform.js",
      hee: true,
      fp: "0f1abc58d350516bb217d384b46f49a849cf7c3a",
      dpo: false,
      le: ["ili", "ipu"],
    },
    platform: [
      "additnow",
      "backdrop",
      "blogger",
      "comments",
      "commentcount",
      "community",
      "donation",
      "family_creation",
      "follow",
      "hangout",
      "health",
      "page",
      "partnersbadge",
      "person",
      "playemm",
      "playreview",
      "plus",
      "plusone",
      "post",
      "ratingbadge",
      "savetoandroidpay",
      "savetodrive",
      "savetowallet",
      "sharetoclassroom",
      "shortlists",
      "signin2",
      "surveyoptin",
      "visibility",
      "youtube",
      "ytsubscribe",
      "zoomableimage",
    ],
    fp: "0f1abc58d350516bb217d384b46f49a849cf7c3a",
    annotation: [
      "interactivepost",
      "recobar",
      "signin2",
      "autocomplete",
      "profile",
    ],
    bimodal: ["signin", "share"],
  },
});
